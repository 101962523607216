<template>
  <div>
    <el-button @click="dialogTableVisible=true">
      {{btnTxt}}
    </el-button>
    <el-dialog title="选择所在区域" destroy-on-close :visible.sync="dialogTableVisible">
      <YChooseAreaPro :mode="1" @change="change"></YChooseAreaPro>
    </el-dialog>
  </div>
</template>

<script>
import YChooseAreaPro from "@/components/shop/YChooseAreaPro/YChooseAreaPro";

export default {
  name: "AreaChoose",
  components: {YChooseAreaPro},
  model: {
    prop: "modelVal",
    event: 'change'
  },
  props: {
    modelVal: {
      type: Object,
      default(){
        return{
          area_p_label: "",
          area_p_code: "",
          area_c_label: "",
          area_c_code: "",
          area_d_label: "",
          area_d_code: "",
          area_s_label: "",
          area_s_code: "",
        }
      }
    },
  },
  watch: {
    // modelVal() {
    //   this.value = this.modelVal;
    // }
  },
  data() {
    return {
      dialogTableVisible:false,
    }
  },
  computed:{
    btnTxt(){
      if(this.modelVal.area_p_label === ""){
        return  "选择所在区域";
      }
      let arr = [];
      arr.push(this.modelVal.area_p_label || "")
      arr.push(this.modelVal.area_c_label || "")
      arr.push(this.modelVal.area_d_label || "")
      arr.push(this.modelVal.area_s_label || "")
      return arr.join("")
    },
  },
  methods: {
    change(e) {
      this.dialogTableVisible =false;
      this.$emit("change", e)
    },
  },
}
</script>

<style scoped>

</style>