<template>
  <div>
    <el-table :data="value">
      <el-table-column label="图片">
        <template v-slot="s">
          <img :src="s.row | tomedia" style="width: 3rem;height: 3rem;border-radius: .5rem">
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <el-button @click="add" type="primary">添加轮播图</el-button>
          <el-button @click="$emit('save')" type="primary">保存变更</el-button>
        </template>
        <template v-slot="s">
          <el-button @click="del(s.$index)" type="danger">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <y_upload_img :show-input="false" ref="uploadImg"></y_upload_img>
  </div>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "BannerEdit",
  components: {y_upload_img},
  model:{
    prop:"modelVal",
    event:"change",
  },
  props:{
    modelVal:{
      type:String,
      default:"",
    }
  },
  watch:{
    modelVal(){
      if(this.modelVal !== ""){
        this.value = this.modelVal.split(",");
      }
    },
  },
  data(){
    return{
      value:[],
    }
  },
  computed:{

  },
  methods:{
    del(index){
      this.value.splice(index,1);
      this.$emit("change", this.value.join(","));
    },
    add(){
      this.$refs.uploadImg.showUpload(e=>{
        this.value.push(e);
        this.$emit("change",this.value.join(","));
      })
    },
  }
}
</script>

<style scoped>

</style>